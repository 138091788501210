<template>
  <div>
    <div v-if="show" class="invoice-box" id="invoice" :style="{'padding': '30px 15px','background-color': '#ffffff',
         'direction': (lang == 'ar'? 'rtl': 'ltr'), 'text-align': (lang == 'ar'? 'right': 'left') }">

      <div style="display: flex;justify-content: space-between;">
        <div>
          <h3><b>{{ $t('inventory_transactions.inventory_transaction') }}</b></h3>
        </div>

      </div>
      <div>
        <table :style="{'text-align': (lang == 'ar'? 'right': 'left') ,'margin-top': '50px'}" cellpadding="0" cellspacing="0">
          <tbody>
          <tr>
            <td>
              <span>{{ $t('inventory_transactions.transaction_type') }}: </span>
              <span v-if="data.transaction_type==1">{{ $t('transaction_types.add_quantity') }}</span>
              <span v-else-if="data.transaction_type==2">{{ $t('transaction_types.decrease_quantity') }}</span>
              <span v-else-if="data.transaction_type==3">{{ $t('transaction_types.transfer_quantity') }}</span>
            </td>
            <td>{{ $t('inventory_transactions.inventory') }}: {{ data.inventory ? data.inventory.name : '' }}</td>
          </tr>
          <tr>

            <td>{{ $t('inventory_transactions.transaction_code') }}: #{{ data.transaction_code }}</td>
            <td>{{ $t('inventory_transactions.transaction_reference') }}: #{{ data.transaction_reference }}</td>
          </tr>
          <tr>
            <td>{{ $t('inventory_transactions.transaction_date') }}: {{ data.transaction_date }}</td>
            <td>{{ $t('inventory_transactions.status') }}: {{ data.status_name }}</td>
          </tr>
          <tr>
            <td colspan="2"> {{ $t('inventory_transactions.notes') }}: {{ data.notes }}</td>
          </tr>

          </tbody>
        </table>
      </div>


      <table :style="{'text-align': (lang == 'ar'? 'right': 'left') ,'margin-top': '50px'}" cellpadding="0" cellspacing="0">
        <thead>
        <tr style="background:#CAD3C8">
          <td class="border color">{{ $t('inventory_transactions.product') }}</td>
          <td class="border color" v-if="is_show_price">{{ $t('inventory_transactions.purchase_price') }}</td>
          <td class="border color">{{ $t('inventory_transactions.quantity') }}</td>
          <td class="border color">{{ $t('inventory_transactions.item_inventory_qty_before') }}</td>
          <td class="border color">{{ $t('inventory_transactions.item_inventory_qty_after') }}</td>
          <td class="border color">{{ $t('inventory_transactions.unit') }}</td>
          <td class="border color" v-if="is_show_price">{{ $t('inventory_transactions.total') }}</td>
        </tr>

        </thead>
        <tbody>
        <tr class="item" v-for="(row, index) in items_list" :key="index">
          <td class="border">{{ row.item.name }}</td>
          <td class="border" v-if="is_show_price">{{ row.purchase_price }}</td>
          <td class="border">{{ row.quantity }}</td>
          <td class="border">{{ row.item_inventory_qty_before }}</td>
          <td class="border">{{ row.item_inventory_qty_after }}</td>
          <td class="border">{{ row.unit ? row.unit.name : '' }}</td>
          <td class="border" v-if="is_show_price">{{ row.total }}</td>
        </tr>

        </tbody>


      </table>
      <br>
      <div :style="{'text-align': (lang == 'ar'? 'left': 'right')}">
        <vue-barcode v-if="data.transaction_code" :value="data.transaction_code" :options="{ displayValue: true }"></vue-barcode>
      </div>

    </div>
  </div>
</template>

<script>
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import ApiService from "@/core/services/api.service";

import VueBarcode from '@chenfengyuan/vue-barcode';
import jsPdfExport from "@/core/config/jsPdfExport";

export default {
  name: "print-purchases-invoice",
  components: {VueBarcode},

  data() {
    return {
      lang: this.$i18n.locale,
      mainRoute: 'items/inventory-transactions',

      data: {},
      items_list: [],
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      type: this.$route.params.type ? this.$route.params.type : null,
      show: false,
      is_show_price: (this.$route.query.is_show_price == 0) ? false : true,
    }
  },

  methods: {
    printInvoice() {
      // let element = document.getElementById('invoice');
      // let pdf = jsPDF("p", "pt", "a4");
      // // let check_lang = this.lang == 'ar' ? 'arabic' : 'english';
      // html2canvas(element).then(canvas => {
      //   let image = canvas.toDataURL('image/png', 1.0);
      //   pdf.addImage(image, 'PNG', 60, 15);
      //   // pdf.save(`${'purchase invoice '}${check_lang}${'.pdf'}`);
      //   pdf.autoPrint();
      //   let _url = pdf.output("bloburl");
      //   location.href = _url;
      // });
      let _id = 'invoice';
      this.exportPDF(_id);
    },
    exportPDF(_id) {
      let that = this;
      // let pdf = jsPDF("p", "px", 'a4');
      //
      // const AmiriRegular = pdf.loadFile("https://rawcdn.githack.com/MrRio/jsPDF/cbc85b4bb66d5c1a2d50d199e8bf33836f2f12d7/test/reference/Amiri-Regular.ttf", true);
      // pdf.addFileToVFS("Amiri-Regular.ttf", AmiriRegular);
      //
      // pdf.addFont("Amiri-Regular.ttf", "Amiri", "normal");
      let pdf = jsPdfExport("p", "px", 'a4');
      pdf.html(document.getElementById(_id), {
        html2canvas: {
          scale: 0.5,
        },
        callback: function (pdf) {
          if (that.type == 'pdf') {
            pdf.save('invoice');
          } else {
            pdf.autoPrint();
            pdf.output('dataurlnewwindow');
          }

          // var iframe = document.createElement('iframe');
          // iframe.setAttribute('style', 'position:absolute;top:0;right:0;height:100%; width:600px');
          // document.body.appendChild(iframe);
          // iframe.src = pdf.output('datauristring');
        }
      });
    },
    async getData() {
      let _id = this.idEditing;
      await ApiService.get(`${this.mainRoute}/${_id}`).then((response) => {

        this.data = response.data.data;
        this.items_list = response.data.data.items.map((row) => {
          let _qty = row.quantity ? parseInt(row.quantity) : 0;
          let _purchase_price = row.purchase_price ? parseFloat(row.purchase_price) : 0;
          let _total = _qty * _purchase_price;
          row.total = _total;
          return row;
        });
        this.show = true;
      });
    },
  },
  mounted() {
    let promise = this.getData();
    Promise.all([promise]).then(() => {
      this.printInvoice();
    });
    // window.print();
  }
}
</script>

<style>
#invoice {
  font-family: "Amiri";
  width: 235mm;
}
canvas {
  height: 100;
}

table, thead, tr, th, tbody, td {
  border: 1px solid #000 !important;
}

.invoice-box {
  background-color: #fff;
  width: 600px;
  margin: auto;
  padding: 30px;
  border: 1px solid #fff;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #000;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  /* padding: 5px; */
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr td:nth-child(3) {
  text-align: right;
}

.invoice-box table tr.top td.title {
  font-size: 45px;
  line-height: 45px;
  color: #000;
}

.padd {
  padding-bottom: 100px;

}

.invoice-box table tr.information td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  border: 1px solid #000 !important;
  background: #CAD3C8;
  border-bottom: 1px solid #fff;
  font-weight: bold;
}

.border {
  border: 1px solid #000 !important;
  padding: 5px;
}

.color {
  background-color: #CAD3C8;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/

.invoice-box.rtl {
  direction: rtl;
  font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.invoice-box.rtl table {
  text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
  text-align: left;
}
</style>